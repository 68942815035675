import { getDocument, PDFDocumentProxy } from "pdfjs-dist"
import { useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { getLeadMagnetFile, searchLeadMagnet } from "services/api.leadmagnet"
import { useAppDispatch, useAppSelector } from "store"
import { setRendering } from "../LeadMagnet.slice"
import { ArrowDownCircleIcon, ArrowLeftCircleIcon, ArrowRightCircleIcon, MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline"
import { addOpenedPageEvent, addReadingPageEvent } from "services/api.event"

export const LeadMagnetPage = () => {
  const dispatch = useAppDispatch()

  const { leadMagnet } = useAppSelector(
    (x) => x.leadMagnet
  )

  const [ pageNo, setPageNo ] = useState<number>(1)

  const [ currPageNo, setCurrPageNo ] = useState<number | null>(null)

  const [ scale, setScale ] = useState<number>(1)

  const [ document, setDocument ] = useState<PDFDocumentProxy | null>(null)

  const [ searchParams ] = useSearchParams()

  const leadMagnetId = searchParams.get("leadMagnetId")

  const leadId = searchParams.get("leadId")

  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(
    /**
     * 
     */
    () => {
      if (
        ! leadMagnetId || ! leadId
      ) {
        return
      }

      dispatch(
        searchLeadMagnet({
            leadMagnetId, leadId
          }
        )
      )

      .unwrap()
    },
      [ leadMagnetId, leadId ]
  )

  useEffect(
    /**
     * 
     */
    () => {
      if (
        ! leadMagnet?.leadMagnetFileId
      ) {
        return
      }
  
      dispatch(
        getLeadMagnetFile()
      )

      .unwrap()

      .then(
        (blob) => {
          setPageNo(1)

          const reader = new FileReader()

          reader.onload = () => {
            const arrayBuffer = reader.result as ArrayBuffer

            getDocument(arrayBuffer).promise

            .then(
              (doc) => setDocument(doc)
            )
          }

          reader.readAsArrayBuffer(blob)
        }
      )
    },
      [ leadMagnet?.leadMagnetFileId ]
  )

  useEffect(
    /**
     * 
     */
    () => {
      if (
        ! document
      ) {
        return
      }

      dispatch(
        setRendering(true)
      )

      document.getPage(pageNo)

      .then(
        (page) => {
          const viewport = page.getViewport({
              scale
            }
          )

          const canvas = canvasRef.current

          if (
            canvas
          ) {
            canvas.height = viewport.height
            canvas.width = viewport.width

            const canvasContext = canvas.getContext("2d")

            if (
              canvasContext
            ) {
              page.render({
                  canvasContext, viewport
                }
              ).promise
              
              .then(
                () => {
                  setCurrPageNo(pageNo)

                  dispatch(
                    setRendering(false)
                  )
                }
              )
            }
          }
        }
      )
    },
      [ document, pageNo, scale ]
  )

  useEffect(
    /**
     * 
     */
    () => {
      if (
        ! currPageNo
      ) {
        return
      }

      dispatch(
        addOpenedPageEvent({
            openedPageEvent: { eventPageNo: currPageNo }
          }
        )
      )

      .unwrap()

      const id = setInterval(
        () => {
          dispatch(
            addReadingPageEvent({
                readingPageEvent: { eventPageNo: currPageNo }
              }
            )
          )

          .unwrap()
        },
          2000
      )

      return () => {
        clearInterval(id)
      }
    },
      [ currPageNo ]
  )

  /**
   * 
   */
  const onDownload = () => {
    dispatch(
      getLeadMagnetFile()
    )

    .unwrap()

    .then(
      (blob: any) => {
        if (
          ! leadMagnet
        ) {
          throw new Error("Lead magnet nie istnieje")
        }

        var url = window.URL.createObjectURL(
          new Blob([
              blob
            ]
          )
        )

        var a = window.document.createElement("a")
        a.style.display = "none"
        a.href = url
        a.download = leadMagnet.leadMagnetFileName

        window.document.body.appendChild(a)

        a.click()

        window.URL.revokeObjectURL(url)
      }
    )
  }

  /**
   * 
   */
  const onNextPage = () => {
    setPageNo(
      (x) => x + 1
    )
  }

  /**
   * 
   */
  const onPrevPage = () => {
    setPageNo(
      (x) => x - 1
    )
  }

  /**
   * 
   */
  const onZoomIn = () => {
    setScale(
      (x) => x + 0.1
    )
  }

  /**
   * 
   */
  const onZoomOut = () => {
    setScale(
      (x) => x - 0.1
    )
  }

  if (
    ! leadMagnet
  ) {
    return (
      <>
      </>
    )
  }

  const canOnPrevPage = pageNo > 1

  const canOnNextPage = pageNo < (document?.numPages || 0)


  return (
    <>
      <div className="relative w-full h-full flex flex-col">
        {document?.numPages ? (
          <div className="shrink w-full flex-row p-4 bg-white border-b border-b-slate-700/10">
            <ul className="flex space-x-4 place-content-center">
              <li>
                <a href="#" className={canOnPrevPage ? "" : "pointer-events-none opacity-25"}
                  onClick={
                    (e) => {
                      e.preventDefault()

                      onPrevPage()
                    }
                  }
                >
                  <ArrowLeftCircleIcon className="h-6 w-6" />
                </a>
              </li>

              <li>
                <a href="#" className={canOnNextPage ? "" : "pointer-events-none opacity-25"}
                  onClick={
                    (e) => {
                      e.preventDefault()

                      onNextPage()
                    }
                  }
                >
                  <ArrowRightCircleIcon className="h-6 w-6" />
                </a>
              </li>

              <li>
                <a href="#"
                  onClick={
                    (e) => {
                      e.preventDefault()

                      onZoomIn()
                    }
                  }
                >
                  <PlusCircleIcon className="h-6 w-6" />
                </a>
              </li>

              <li>
                <a href="#"
                  onClick={
                    (e) => {
                      e.preventDefault()

                      onZoomOut()
                    }
                  }
                >
                  <MinusCircleIcon className="h-6 w-6" />
                </a>
              </li>

              {leadMagnet.leadMagnetDownloadable ? (
                <li>
                  <a href="#"
                    onClick={
                      (e) => {
                        e.preventDefault()

                        onDownload()
                      }
                    }
                  >
                    <ArrowDownCircleIcon className="h-6 w-6" />
                  </a>
                </li>
              ) : null}
            </ul>
          </div>
        ) : null}

        <div className="grow grid items-center justify-center bg-gray-50 overflow-auto">
          <div className="shadow-xl shadow-black/5">
            <canvas ref={canvasRef} />
          </div>
        </div>
      </div>
    </>
  )
}
